// App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TherapistList from './components/TherapistList';
import BookingForm from './components/BookingForm';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSignInAlt, faUserPlus, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Login from './components/Login';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import TherapistDashboard from './components/TherapistDashboard';
import ChangePassword from './components/ChangePassword';
import AdminDashboard from './components/AdminDashboard';
import RegisterForm from './components/RegisterForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import AdminLogin from './components/AdminLogin';
import { useParams } from 'react-router-dom';
import config from './config';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('therapistList');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [contactCounts, setContactCounts] = useState({ prospect: 0, customer: 0, vvip: 0 });

  // Function untuk fetch kiraan contact (prospek, pelanggan, VVIP)
  const fetchContactCounts = async (therapistId) => {
    try {
      const token = localStorage.getItem('token'); // Ambil token jika perlu
      const response = await axios.get(`${config.API_URL}/api/prospects/count/${therapistId}`, {
        headers: { 'x-auth-token': token },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching contact counts:', error);
      return { prospect: 0, customer: 0, vvip: 0 };
    }
  };

  // useEffect untuk panggil fetchContactCounts semasa app dimuatkan
  useEffect(() => {
    const getCounts = async () => {
      const therapistId = localStorage.getItem('therapistId'); // Dapatkan therapistId
      if (therapistId) {
        const counts = await fetchContactCounts(therapistId);
        setContactCounts(counts); // Simpan counts dalam state
      }
    };
    getCounts();
  }, []);

  const handleRegisterSuccess = () => {
    setActiveTab('therapistList');
    navigate('/therapist-list');
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setIsSidebarOpen(false);
    switch (tab) {
      case 'therapistList':
        navigate('/therapist-list');
        break;
      case 'login':
        navigate('/login');
        break;
      case 'register':
        navigate('/register');
        break;
      default:
        navigate('/');
    }
  };

  const handleOpenModal = (therapist) => {
    setSelectedTherapist(therapist);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTherapist(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Route untuk buka booking form berdasarkan therapist ID
  const TherapistBooking = () => {
    const { therapistId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [localIsModalOpen, setLocalIsModalOpen] = useState(false);
    const [localSelectedTherapist, setLocalSelectedTherapist] = useState(null);

    useEffect(() => {
      const fetchTherapistById = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(`${config.API_URL}/therapists/${therapistId}`);
          setLocalSelectedTherapist(response.data);
          setLocalIsModalOpen(true);
        } catch (error) {
          console.error('Error fetching therapist details:', error);
          setError('Gagal memuat data terapis');
        } finally {
          setIsLoading(false);
        }
      };
      fetchTherapistById();
    }, [therapistId]);

    const handleLocalClose = () => {
      setLocalIsModalOpen(false);
      setLocalSelectedTherapist(null);
      navigate('/therapist-list');
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
      localIsModalOpen && localSelectedTherapist && (
        <div className="modal-overlay" onClick={handleLocalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button 
              className="close-button" 
              onClick={handleLocalClose}
              style={{ position: 'absolute', right: '10px', top: '10px' }}
            >
              ✕
            </button>
            <BookingForm 
              selectedTherapist={localSelectedTherapist} 
              onClose={handleLocalClose} 
            />
          </div>
        </div>
      )
    );
  };

  // Kemas kini shouldShowNavigation untuk menyembunyikan navigasi pada /admin-login
  const shouldShowNavigation =
    location.pathname !== '/therapist-dashboard' && 
    location.pathname !== '/admin-dashboard' && 
    location.pathname !== '/admin-login';

  return (
    <div className="App">
      <header className="main-header">
        <div className="header-content">
          <h1>Malaysian Therapist Booking System</h1>
          <div className="powered-by">
            Develop by: <i>Afif Akmal_Matin Therapy</i>
          </div>
        </div>
        {shouldShowNavigation && (
          <button className="menu-toggle" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
          </button>
        )}
      </header>

      {shouldShowNavigation && (
        <nav className="desktop-nav">
          <button
            className={`nav-button ${activeTab === 'therapistList' ? 'active' : ''}`}
            onClick={() => handleTabChange('therapistList')}
          >
            <FontAwesomeIcon icon={faList} /> Therapist List
          </button>
          <button
            className={`nav-button ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => handleTabChange('login')}
          >
            <FontAwesomeIcon icon={faSignInAlt} /> Login
          </button>
          <button
            className={`nav-button ${activeTab === 'register' ? 'active' : ''}`}
            onClick={() => handleTabChange('register')}
          >
            <FontAwesomeIcon icon={faUserPlus} /> New Register
          </button>
        </nav>
      )}

      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <nav>
          <button className="close-sidebar" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <button
            className={`nav-button ${activeTab === 'therapistList' ? 'active' : ''}`}
            onClick={() => handleTabChange('therapistList')}
          >
            <FontAwesomeIcon icon={faList} /> Therapist List
          </button>
          <button
            className={`nav-button ${activeTab === 'login' ? 'active' : ''}`}
            onClick={() => handleTabChange('login')}
          >
            <FontAwesomeIcon icon={faSignInAlt} /> Login
          </button>
          <button
            className={`nav-button ${activeTab === 'register' ? 'active' : ''}`}
            onClick={() => handleTabChange('register')}
          >
            <FontAwesomeIcon icon={faUserPlus} /> New Register
          </button>
        </nav>
      </div>

      <main className="main-content">
        <Routes>
          <Route path="/" element={<Navigate to="/therapist-list" />} />
          <Route path="/therapist-list" element={<TherapistList onBooking={handleOpenModal} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterForm onRegisterSuccess={handleRegisterSuccess} />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/therapist-dashboard" element={<TherapistDashboard />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/therapist/:therapistId" element={<TherapistBooking />} />
        </Routes>
      </main>

      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <BookingForm selectedTherapist={selectedTherapist} onClose={handleCloseModal} />
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
