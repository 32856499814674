// AdminDashboard.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ManageTherapists from './ManageTherapists';
import ManageBookings from './ManageBookings';
import DashboardStatistics from './DashboardStatistics';
import { FaChartBar, FaUserMd, FaCalendarCheck, FaSignOutAlt } from 'react-icons/fa';
import './AdminDashboard.css';
import WhatsappIntegrationAdmin from './WhatsappIntegrationAdmin';


function AdminDashboard() {
  const [activeTab, setActiveTab] = useState('dashboard');
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    navigate('/login');
  };


  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="admin-dashboard">
      <header className="dashboard-header">
        <h1>Selamat datang, Admin!</h1>
        <button className="logout-button desktop-only" onClick={handleLogout}>
          Logout
        </button>
      </header>

      <div className="tabs desktop-only">
        <button
          onClick={() => setActiveTab('dashboard')}
          className={`tab-button ${activeTab === 'dashboard' ? 'active' : ''}`}
        >
          Dashboard
        </button>
        <button
          onClick={() => setActiveTab('manageTherapists')}
          className={`tab-button ${activeTab === 'manageTherapists' ? 'active' : ''}`}
        >
          Urus Terapis
        </button>
        <button
  onClick={() => setActiveTab('whatsappIntegrationAdmin')}
  className={`tab-button ${activeTab === 'whatsappIntegrationAdmin' ? 'active' : ''}`}
>
  WhatsApp Integration
</button>
        <button
          onClick={() => setActiveTab('manageBookings')}
          className={`tab-button ${activeTab === 'manageBookings' ? 'active' : ''}`}
        >
          Urus Tempahan
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'dashboard' && (
          <div className="content">
            <DashboardStatistics />
            <p>Selamat datang ke dashboard admin. Anda boleh mengurus sistem dari sini.</p>
          </div>
        )}
        {activeTab === 'manageTherapists' && <ManageTherapists />}
        {activeTab === 'manageBookings' && <ManageBookings />}
        {activeTab === 'whatsappIntegrationAdmin' && <WhatsappIntegrationAdmin />}
      </div>

      {/* Navigasi Bawah untuk Mudah Alih */}
      <div className="bottom-navigation mobile-only">
        <button
          className={`bottom-nav-button ${activeTab === 'dashboard' ? 'active' : ''}`}
          onClick={() => handleTabClick('dashboard')}
        >
          <FaChartBar />
          <span>Dashboard</span>
        </button>
        <button
          className={`bottom-nav-button ${activeTab === 'manageTherapists' ? 'active' : ''}`}
          onClick={() => handleTabClick('manageTherapists')}
        >
          <FaUserMd />
          <span>Terapis</span>
        </button>
        <button
          className={`bottom-nav-button ${activeTab === 'manageBookings' ? 'active' : ''}`}
          onClick={() => handleTabClick('manageBookings')}
        >
          <FaCalendarCheck />
          <span>Tempahan</span>
        </button>
        <button className="bottom-nav-button" onClick={handleLogout}>
          <FaSignOutAlt />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
}

export default AdminDashboard;
