import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Customer.css';
import { toast } from 'react-toastify';

const Customer = ({ therapistId }) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [repeatedBookings, setRepeatedBookings] = useState({});
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  useEffect(() => {
    if (therapistId) {
      fetchCustomers();
    }
  }, [therapistId]);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      setSelectedCustomers([]); // Reset selections when fetching
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/customers/${therapistId}`,
        {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (Array.isArray(response.data)) {
        const customersData = response.data;
        setCustomers(customersData);
        
        // Fetch repeated bookings count for each customer
        const repeatedData = {};
        await Promise.all(
          customersData.map(async (customer) => {
            try {
              const repeatedResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/customers/repeated/${customer.phone}`,
                {
                  headers: { 'x-auth-token': token }
                }
              );
              repeatedData[customer.phone] = repeatedResponse.data.count;
            } catch (error) {
              console.error(`Error fetching repeated count for ${customer.phone}:`, error);
              repeatedData[customer.phone] = 0;
            }
          })
        );
        setRepeatedBookings(repeatedData);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError(true);
      toast.error('Gagal memuat data pelanggan');
    } finally {
      setLoading(false);
    }
  };

  // Handle checkbox selection
  const handleSelectCustomer = (customerId) => {
    setSelectedCustomers(prev => {
      if (prev.includes(customerId)) {
        return prev.filter(id => id !== customerId);
      } else {
        return [...prev, customerId];
      }
    });
  };

  // Handle select all
  const handleSelectAll = () => {
    if (selectedCustomers.length === customers.length) {
      setSelectedCustomers([]);
    } else {
      setSelectedCustomers(customers.map(c => c._id));
    }
  };

  // Delete multiple customers
  const deleteMultipleCustomers = async () => {
    if (selectedCustomers.length === 0) {
      toast.warning('Sila pilih pelanggan untuk dipadamkan');
      return;
    }

    if (!window.confirm(`Adakah anda pasti untuk memadam ${selectedCustomers.length} pelanggan yang dipilih?`)) {
      return;
    }

    try {
      setIsDeleting(true);
      const token = localStorage.getItem('token');
      
      await axios.post('/api/customers/delete-multiple', {
        customerIds: selectedCustomers
      }, {
        headers: {
          'x-auth-token': token
        }
      });

      // Update local state
      setCustomers(customers.filter(c => !selectedCustomers.includes(c._id)));
      setSelectedCustomers([]);
      toast.success('Pelanggan berjaya dipadamkan');
    } catch (error) {
      console.error('Error deleting customers:', error);
      toast.error('Gagal memadam pelanggan');
    } finally {
      setIsDeleting(false);
    }
  };

  // Single delete customer function
  const deleteCustomer = async (id) => {
    try {
      setDeletingId(id);
      const token = localStorage.getItem('token');
      
      await axios.delete(`/api/customers/${id}`, {
        headers: {
          'x-auth-token': token
        }
      });

      setCustomers(customers.filter(customer => customer._id !== id));
      toast.success('Pelanggan berjaya dipadamkan');
    } catch (error) {
      console.error('Error deleting customer:', error);
      toast.error('Gagal memadam pelanggan');
    } finally {
      setDeletingId(null);
    }
  };

  // Confirm single delete
  const confirmDelete = (id, name) => {
    if (window.confirm(`Adakah anda pasti untuk memadam pelanggan ${name || 'ini'}?`)) {
      deleteCustomer(id);
    }
  };

  const getStatusBadgeClass = (status) => {
    return status === 'completed' ? 'status-badge completed' : 'status-badge in-progress';
  };

  const getRepeatedBadgeClass = (count) => {
    if (count >= 3) return 'repeated-badge vvip';
    if (count === 2) return 'repeated-badge regular';
    return 'repeated-badge new';
  };

  const formatDate = (date) => {
    if (!date) return '-';
    return new Date(date).toLocaleString('ms-MY', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  if (loading) return <div className="loading-state">Memuatkan pelanggan...</div>;
  if (error) return <div className="error-state">Ralat memuatkan pelanggan. Sila cuba lagi.</div>;
  if (!customers.length) return <div className="empty-state">Tiada pelanggan ditemui.</div>;

  return (
    <div className="customer-list">
      <div className="list-header">
        <h2>Senarai Pelanggan</h2>
        {selectedCustomers.length > 0 && (
          <button 
            onClick={deleteMultipleCustomers}
            className="delete-selected-button"
            disabled={isDeleting}
          >
            {isDeleting ? 'Memadan...' : `Padam (${selectedCustomers.length})`}
          </button>
        )}
      </div>
      <div className="table-container">
        <table className="customer-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectedCustomers.length === customers.length}
                  onChange={handleSelectAll}
                  className="checkbox-input"
                />
              </th>
              <th>Nama</th>
              <th>No Telefon</th>
              <th>Rawatan</th>
              <th>Tarikh Tempahan</th>
              <th>Masa</th>
              <th>Nama Therapist</th>
              <th>Status</th>
              <th>Jumlah Tempahan</th>
              <th>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => (
              <tr key={customer._id} className={selectedCustomers.includes(customer._id) ? 'selected-row' : ''}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedCustomers.includes(customer._id)}
                    onChange={() => handleSelectCustomer(customer._id)}
                    className="checkbox-input"
                  />
                </td>
                <td>{customer.name}</td>
                <td>{customer.phone}</td>
                <td>{customer.treatment}</td>
                <td>{formatDate(customer.bookingDate)}</td>
                <td>{customer.startTime} - {customer.endTime}</td>
                <td>{customer.therapistName}</td>
                <td>
                  <span className={getStatusBadgeClass(customer.status)}>
                    {customer.status === 'completed' ? 'Selesai' : 'Dalam Proses'}
                  </span>
                </td>
                <td>
                  <span className={getRepeatedBadgeClass(repeatedBookings[customer.phone] || 0)}>
                    {repeatedBookings[customer.phone] || 0} kali
                  </span>
                </td>
                <td>
                  <button
                    onClick={() => confirmDelete(customer._id, customer.name)}
                    className="delete-button"
                    disabled={deletingId === customer._id}
                  >
                    {deletingId === customer._id ? 'Memadan...' : 'Padam'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Customer;