// TherapistDashboard.js

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './TherapistDashboard.css';
import EditProfileForm from './EditProfileForm';
import ChangePassword from './ChangePassword';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AvailableSlot from './AvailableSlot';
import MyBookings from './MyBookings';
import { FaCalendarAlt, FaCogs, FaBook, FaUser, FaLock, FaBars, FaEdit, FaCalendarCheck } from 'react-icons/fa'; // Import ikon tambahan
import GoogleCalendarIntegration from './GoogleCalendarIntegration';
import config from '../config';
import CRMDashboard from './CRMDashboard';
import SubscriptionInfo from './SubscriptionInfo';


function TherapistDashboard() {
  const navigate = useNavigate();
  const [therapist, setTherapist] = useState(null);
  const [activeTab, setActiveTab] = useState('profile');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State untuk menu mobile
  const token = localStorage.getItem('token');

  const fetchTherapist = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_URL}/therapist-profile`, {
        headers: { 'x-auth-token': token },
      });
      const therapistData = response.data;
      
      // Pastikan location dalam format yang betul
      if (therapistData.location && typeof therapistData.location === 'object') {
        therapistData.location = `${therapistData.location.state || ''}, ${therapistData.location.city || ''}`.trim();
        if (therapistData.location === ',') therapistData.location = 'Not specified';
      }

      setTherapist(therapistData);
    } catch (error) {
      console.error('Error fetching therapist profile:', error);
      navigate('/login');
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchTherapist();
  }, [fetchTherapist]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    navigate('/login');
  };

  const handleUpdateProfile = async (updatedProfile) => {
    setIsUpdating(true);
    try {
      const response = await axios.put(
        `${config.API_URL}/update-therapist-profile`,
        updatedProfile,
        {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data && response.data._id) {
        setTherapist(response.data);
        toast.success('Profil berjaya dikemas kini!');
        setActiveTab('profile');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Gagal mengemas kini profil. Sila cuba lagi.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handlePasswordChanged = () => {
    toast.success('Kata laluan berjaya ditukar!');
    setActiveTab('profile');
  };

  if (!therapist) {
    return <div>Loading...</div>;
  }

  // Fungsi untuk menutup menu mobile saat memilih tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setIsMobileMenuOpen(false);
  };

return (
    <div className="therapist-dashboard">
      <div className="dashboard-header">
        <h1>Therapist Dashboard</h1>
        {/* Tab Buttons untuk Desktop */}
        <div className="header-actions desktop-only">
          <div className="tab-buttons">
            <button
              className={`tab-button ${activeTab === 'availableSlot' ? 'active' : ''}`}
              onClick={() => setActiveTab('availableSlot')}
            >
              <FaCalendarAlt /> Available Slot
            </button>
            <button
              className={`tab-button ${activeTab === 'crm' ? 'active' : ''}`}
              onClick={() => setActiveTab('crm')}
            >
              <FaCogs /> CRM
            </button>
            <button
              className={`tab-button ${activeTab === 'myBookings' ? 'active' : ''}`}
              onClick={() => setActiveTab('myBookings')}
            >
              <FaBook /> My Bookings
            </button>
            <button
              className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
              onClick={() => setActiveTab('profile')}
            >
              <FaUser /> Profile
            </button>
            <button
              className={`tab-button ${activeTab === 'changePassword' ? 'active' : ''}`}
              onClick={() => setActiveTab('changePassword')}
            >
              <FaLock /> Change Password
            </button>
            <button
              className={`tab-button ${activeTab === 'integrateGoogleCalendar' ? 'active' : ''}`}
              onClick={() => setActiveTab('integrateGoogleCalendar')}
            >
              <FaCalendarCheck /> Integrate Google Calendar
            </button>
          </div>
          {/* Tambahkan Komponen SubscriptionInfo di sini */}
          <SubscriptionInfo
            subscriptionPlan={therapist.subscriptionPlan}
            subscriptionStartDate={therapist.subscriptionStartDate}
            subscriptionEndDate={therapist.subscriptionEndDate}
          />
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>
        <button className="mobile-menu-button mobile-only" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          <FaBars />
        </button>
      </div>

      {/* Menu Mobile */}
      {isMobileMenuOpen && (
        <div className="mobile-menu mobile-only">
          <SubscriptionInfo
            subscriptionPlan={therapist.subscriptionPlan}
            subscriptionStartDate={therapist.subscriptionStartDate}
            subscriptionEndDate={therapist.subscriptionEndDate}
          />
          <button
            className={`mobile-tab-button ${activeTab === 'availableSlot' ? 'active' : ''}`}
            onClick={() => handleTabClick('availableSlot')}
          >
            <FaCalendarAlt /> Available Slot
          </button>
          <button
            className={`mobile-tab-button ${activeTab === 'crm' ? 'active' : ''}`}
            onClick={() => handleTabClick('crm')}
          >
            <FaCogs /> CRM
          </button>
          <button
            className={`mobile-tab-button ${activeTab === 'myBookings' ? 'active' : ''}`}
            onClick={() => handleTabClick('myBookings')}
          >
            <FaBook /> My Bookings
          </button>
          <button
            className={`mobile-tab-button ${activeTab === 'profile' ? 'active' : ''}`}
            onClick={() => handleTabClick('profile')}
          >
            <FaUser /> Profile
          </button>
          <button
            className={`mobile-tab-button ${activeTab === 'changePassword' ? 'active' : ''}`}
            onClick={() => handleTabClick('changePassword')}
          >
            <FaLock /> Change Password
          </button>
          <button onClick={handleLogout} className="mobile-logout-button">
            Logout
          </button>
        </div>
      )}

      <div className="dashboard-content">
        {activeTab === 'profile' && (
          <div className="profile-card">
            <div className="profile-header">
              <img
                src={therapist.image || '/images/user.png'}
                alt={`${therapist.name} profile`}
                className="therapist-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/user.png';
                }}
              />

              <h2>{therapist.name.toUpperCase()}</h2>
            </div>
            <div className="profile-details">
              <div className="detail-item">
                <span className="detail-label">Phone:</span>
                <span className="detail-value">{therapist.phone}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Email:</span>
                <span className="detail-value">{therapist.email}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Location:</span>
                <span className="detail-value">
                  {therapist.location && typeof therapist.location === 'object'
                    ? `${therapist.location.state || ''}, ${therapist.location.city || ''}`
                    : therapist.location || 'Not specified'}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Jenis Perkhidmatan:</span>
                <span className="detail-value">
                  {therapist.inCall && therapist.outCall
                    ? 'In-Call & Out-Call'
                    : therapist.inCall
                    ? 'In-Call'
                    : therapist.outCall
                    ? 'Out-Call'
                    : 'Tidak dinyatakan'}
                </span>
              </div>

              <div className="detail-item">
                <span className="detail-label">Jenis rawatan yang disediakan untuk:</span>
                <span className="detail-value">
                  {therapist.jenisRawatan.lelaki && therapist.jenisRawatan.wanita
                    ? 'Lelaki & Wanita'
                    : therapist.jenisRawatan.lelaki
                    ? 'Lelaki'
                    : 'Wanita'}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Jenis Rawatan:</span>
                <div className="treatment-types">
                  {therapist.jenisRawatan.rawatanLain.map((rawatan, index) => (
                    <span key={index} className="treatment-tag">
                      {rawatan}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            {/* Butang Edit Profile Ditambahkan di Sini */}
            <div className="profile-actions">
              <button onClick={() => setActiveTab('editProfile')} className="edit-profile-button">
                <FaEdit /> Edit Profile
              </button>
            </div>
          </div>
        )}
        {activeTab === 'availableSlot' && (
          <AvailableSlot therapist={therapist} />
        )}
        {activeTab === 'myBookings' && (
          <MyBookings therapist={therapist} />
        )}
        {activeTab === 'editProfile' && (
          <EditProfileForm
            therapist={therapist}
            onUpdate={handleUpdateProfile}
            onCancel={() => setActiveTab('profile')}
          />
        )}
        {activeTab === 'changePassword' && (
          <ChangePassword 
            therapistId={therapist._id}
            onPasswordChanged={handlePasswordChanged}
          />
        )}
        {activeTab === 'integrateGoogleCalendar' && (
          <GoogleCalendarIntegration token={token} therapistId={therapist._id} />
        )}
        {activeTab === 'crm' && (
          <CRMDashboard />
        )}
        {isUpdating && <div className="loader">Updating profile...</div>}
      </div>

      {/* Bottom Navigation for Mobile */}
      <div className="bottom-navigation mobile-only">
        <button
          className={`bottom-nav-button ${activeTab === 'availableSlot' ? 'active' : ''}`}
          onClick={() => handleTabClick('availableSlot')}
        >
          <FaCalendarAlt />
          <span>Slot</span>
        </button>
        <button
          className={`bottom-nav-button ${activeTab === 'myBookings' ? 'active' : ''}`}
          onClick={() => handleTabClick('myBookings')}
        >
          <FaBook />
          <span>Bookings</span>
        </button>
        <button
          className={`bottom-nav-button ${activeTab === 'crm' ? 'active' : ''}`}
          onClick={() => handleTabClick('crm')}
        >
          <FaCogs />
          <span>CRM</span>
        </button>
        <button
          className={`bottom-nav-button ${activeTab === 'integrateGoogleCalendar' ? 'active' : ''}`}
          onClick={() => handleTabClick('integrateGoogleCalendar')}
        >
          <FaCalendarCheck />
          <span>Google Integrate</span>
        </button>
        <button
          className="bottom-nav-button"
          onClick={handleLogout}
        >
          <FaLock />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
}

export default TherapistDashboard;