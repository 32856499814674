// components/CRMDashboard.js
import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaUser, FaUsers, FaStar, FaBroadcastTower } from 'react-icons/fa';
import './CRMDashboard.css';
import WhatsappIntegration from './WhatsappIntegration';
import ProspectList from './ProspectList';
import Customer from './Customer';
import VVIPCustomer from './VVIPCustomer';
import { toast } from 'react-toastify';
import Broadcast from './Broadcast';


const CRMDashboard = () => {
  const [activeTab, setActiveTab] = useState('whatsapp');
  const [therapistId, setTherapistId] = useState(null);

  useEffect(() => {
    const storedTherapistId = localStorage.getItem('userId');
    if (!storedTherapistId) {
      toast.error('Sesi terputus. Sila log masuk semula.');
      return;
    }
    setTherapistId(storedTherapistId);
  }, []);

  if (!therapistId) {
    return <div className="loading-container">Memuat...</div>;
  }

  return (
    <div className="crm-dashboard">
      <div className="crm-tabs">
        <button
          className={`crm-tab ${activeTab === 'whatsapp' ? 'active' : ''}`}
          onClick={() => setActiveTab('whatsapp')}
        >
          <FaWhatsapp /> <span>Integrasi WhatsApp</span>
        </button>
        <button
  className={`crm-tab ${activeTab === 'broadcast' ? 'active' : ''}`}
  onClick={() => setActiveTab('broadcast')}
>
  <FaBroadcastTower /> <span>Broadcast</span>
</button>
        <button
          className={`crm-tab ${activeTab === 'prospects' ? 'active' : ''}`}
          onClick={() => setActiveTab('prospects')}
        >
          <FaUser /> <span>Prospek</span>
        </button>
        <button
          className={`crm-tab ${activeTab === 'customers' ? 'active' : ''}`}
          onClick={() => setActiveTab('customers')}
        >
          <FaUsers /> <span>Pelanggan</span>
        </button>
        <button
          className={`crm-tab vvip ${activeTab === 'vvip' ? 'active' : ''}`}
          onClick={() => setActiveTab('vvip')}
        >
          <FaStar /> <span>VVIP</span>
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'whatsapp' && <WhatsappIntegration therapistId={therapistId} />}
        {activeTab === 'broadcast' && <Broadcast therapistId={therapistId} />}
        {activeTab === 'prospects' && <ProspectList therapistId={therapistId} />}
        {activeTab === 'customers' && <Customer therapistId={therapistId} />}
        {activeTab === 'vvip' && <VVIPCustomer therapistId={therapistId} />}
      </div>
    </div>
  );
};

export default CRMDashboard;