// src/components/WhatsappIntegration.jsx

import React, { useState, useEffect, useRef } from 'react';
import QRCode from "react-qr-code";
import './WhatsappIntegration.css';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css';

const WhatsappIntegration = ({ therapistId }) => {
  const [qrCodeData, setQrCodeData] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('disconnected');
  const [showGetQrButton, setShowGetQrButton] = useState(false);
  const sessionId = therapistId;
  const pollingRef = useRef(null);

  // Enum untuk status
  const STATUS = {
    DISCONNECTED: 'disconnected',
    SESSION_STARTED: 'session_started',
    QR_PENDING: 'qr_pending',
    CONNECTED: 'connected',
    FAILED: 'failed', // Tambahkan status FAILED
  };

  // Semak status sesi pada load pertama
  useEffect(() => {
    const checkInitialStatus = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get(`/api/whatsapp/session-status/${sessionId}`, {
          headers: {
            'x-auth-token': token,
          },
        });
        const currentStatus = response.data.status;
        console.log('Status Sesi Semasa:', currentStatus);

        if (currentStatus === 'CONNECTED') {
          setStatus(STATUS.CONNECTED);
        } else if (currentStatus === 'FAILED' || currentStatus === 'DISCONNECTED') {
          setStatus(STATUS.DISCONNECTED);
        } else if (currentStatus === 'SESSION_STARTED') {
          setStatus(STATUS.SESSION_STARTED);
          toast.info('Sesi lama masih wujud. Sila reset sambungan terlebih dahulu.');
        } else {
          setStatus(STATUS.DISCONNECTED);
        }
      } catch (error) {
        console.error('Ralat semasa semak status awal:', error);
        setStatus(STATUS.DISCONNECTED);
        toast.error('Gagal menyemak status sesi. Sila cuba lagi.');
      } finally {
        setLoading(false);
      }
    };

    checkInitialStatus();
  }, [sessionId]);

  // Sambung WhatsApp
  const handleConnectButtonClick = async () => {
    if (status === STATUS.SESSION_STARTED) {
      toast.info('Sesi lama masih wujud. Sila reset sambungan terlebih dahulu.');
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.get(`/api/whatsapp/start-session/${sessionId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      console.log('Sesi dimulakan dengan jayanya');
      setStatus(STATUS.SESSION_STARTED);
      setShowGetQrButton(true);
    } catch (error) {
      console.error('Ralat semasa menyambung:', error);
      toast.error('Gagal menyambung ke WhatsApp. Sila cuba lagi.');
    } finally {
      setLoading(false);
    }
  };

  // Dapatkan QR Code
  const handleGetQrCodeClick = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/whatsapp/session-qr/${sessionId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      const qrData = response.data.qrCodeData;
      if (qrData) {
        setQrCodeData(qrData);
        setStatus(STATUS.QR_PENDING);
        setShowGetQrButton(false);
        startPollingStatus();
      } else {
        console.error('Ralat: Data QR tidak tersedia');
        toast.error('QR Code tidak tersedia. Sila cuba semula.');
        setShowGetQrButton(true);
      }
    } catch (error) {
      console.error('Ralat semasa mendapatkan QR code:', error);
      toast.error('Gagal mendapatkan QR Code. Sila cuba lagi.');
      setShowGetQrButton(true);
    } finally {
      setLoading(false);
    }
  };

  // Polling status sesi
  const startPollingStatus = () => {
    if (pollingRef.current) return;

    let attempts = 0;
    const maxAttempts = 12;

    pollingRef.current = setInterval(async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/api/whatsapp/session-status/${sessionId}`, {
          headers: {
            'x-auth-token': token,
          },
        });
        const currentStatus = response.data.status;
        console.log('Semak Status Sambungan -', currentStatus);
        attempts += 1;

        if (currentStatus === 'CONNECTED') {
          setStatus(STATUS.CONNECTED);
          setQrCodeData('');
          clearInterval(pollingRef.current);
          pollingRef.current = null;
          toast.success('WhatsApp berjaya disambung.');
        } else if (currentStatus === 'FAILED' || currentStatus === 'DISCONNECTED') {
          console.warn('QR Code tidak diimbas atau sambungan gagal.');
          await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
            headers: {
              'x-auth-token': token,
            },
          });
          setStatus(STATUS.DISCONNECTED);
          setQrCodeData('');
          clearInterval(pollingRef.current);
          pollingRef.current = null;
          toast.error('Sesi tamat. Sila cuba sambung semula.');
        } else if (attempts >= maxAttempts) {
          console.warn('Polling telah mencapai bilangan percubaan maksimum.');
          clearInterval(pollingRef.current);
          pollingRef.current = null;
          toast.error('Percubaan maksimum tercapai. Klik untuk reconnect.');
        }
      } catch (error) {
        console.error('Ralat semasa semak status:', error);
        clearInterval(pollingRef.current);
        pollingRef.current = null;
        setStatus(STATUS.DISCONNECTED);
        setQrCodeData('');
        toast.error('Gagal menyemak status sesi.');
      }
    }, 5000);
  };

  // Reset sambungan
  const handleResetConnectionClick = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      console.log('Semua sesi telah ditamatkan');
      setStatus(STATUS.DISCONNECTED);
      setQrCodeData('');
      setShowGetQrButton(false);
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
        pollingRef.current = null;
      }
      toast.success('Sambungan telah direset.');
    } catch (error) {
      console.error('Ralat semasa reset sambungan:', error);
      toast.error('Gagal reset sambungan. Sila cuba lagi.');
    } finally {
      setLoading(false);
    }
  };

  // Putuskan sambungan
  const handleDisconnectButtonClick = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.get(`/api/whatsapp/terminate-session/${sessionId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      console.log('Sesi telah diputuskan');
      setStatus(STATUS.DISCONNECTED);
      setQrCodeData('');
      setShowGetQrButton(false);
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
        pollingRef.current = null;
      }
      toast.success('Sambungan telah diputuskan.');
    } catch (error) {
      console.error('Ralat semasa putuskan sambungan:', error);
      toast.error('Gagal putuskan sambungan. Sila cuba lagi.');
    } finally {
      setLoading(false);
    }
  };

  // Cleanup polling apabila komponen dimusnahkan
  useEffect(() => {
    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, []);

  return (
    <div className="whatsapp-integration-container">
      <h2>Integrasi WhatsApp</h2>
      <p>
        Status:{' '}
        {loading
          ? 'Sila tunggu, sedang menyemak status sambungan anda...'
          : status === STATUS.CONNECTED
          ? 'WhatsApp Berjaya Disambung'
          : status === STATUS.QR_PENDING
          ? 'Dalam Proses Sambungan (QR Diperlukan)'
          : status === STATUS.SESSION_STARTED
          ? 'Sesi Dimulakan. Sila dapatkan QR Code.'
          : 'Tidak Disambung'}
      </p>

      {/* Paparkan notifikasi */}
      <ToastContainer />

      {/* Spinner semasa loading */}
      {loading && (
        <div className="spinner-container">
          <ClipLoader color="#4CAF50" loading={loading} size={50} />
        </div>
      )}

      {/* Butang "Sambung WhatsApp" */}
      <button
        id="connectButton"
        onClick={handleConnectButtonClick}
        disabled={loading || status === STATUS.CONNECTED || status === STATUS.SESSION_STARTED}
      >
        Sambung WhatsApp
      </button>

      {/* Butang "Dapatkan QR Code" */}
      {showGetQrButton && status === STATUS.SESSION_STARTED && (
        <button
          id="getQrButton"
          onClick={handleGetQrCodeClick}
          disabled={loading}
        >
          Dapatkan QR Code
        </button>
      )}

      {/* Butang "Putuskan Sambungan" */}
      {status === STATUS.CONNECTED && (
        <button id="disconnectButton" onClick={handleDisconnectButtonClick} disabled={loading}>
          Putuskan Sambungan
        </button>
      )}

      {/* Butang "Reset Sambungan" */}
      {(status === STATUS.SESSION_STARTED || status === STATUS.QR_PENDING) && (
        <button id="resetButton" onClick={handleResetConnectionClick} disabled={loading}>
          Reset Sambungan
        </button>
      )}

      {/* Paparkan QR Code */}
      {qrCodeData && status === STATUS.QR_PENDING && (
        <div id="qrCodeContainer">
          <QRCode value={qrCodeData} size={200} />
          <p>Sila imbas QR Code ini dengan WhatsApp anda.</p>
        </div>
      )}
    </div>
  );
};

export default WhatsappIntegration;