// ManageTherapists.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ManageTherapists.css';
import { toast } from 'react-toastify';
import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
import config from '../config';

function ManageTherapists() {
  const [therapists, setTherapists] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editTherapist, setEditTherapist] = useState(null);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [subscriptionPlan, setSubscriptionPlan] = useState('basic');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState('');
  const [subscriptionEndDate, setSubscriptionEndDate] = useState('');

 // Tambah state untuk pelan langganan

  useEffect(() => {
    fetchTherapists();
    fetchStates();
  }, []);

  const fetchTherapists = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/therapists`);
      setTherapists(response.data);
    } catch (error) {
      console.error('Error fetching therapists:', error);
      toast.error('Gagal mendapatkan senarai terapis. Sila cuba lagi.');
    }
  };

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/api/states`);
      setStates(response.data);
    } catch (error) {
      console.error('Error fetching states:', error);
      toast.error('Gagal mendapatkan senarai negeri. Sila cuba lagi.');
    }
  };

  const handleEdit = (therapist) => {
    setEditTherapist({ ...therapist });
    setSubscriptionPlan(therapist.subscriptionPlan || 'basic'); // Set pelan langganan semasa
    setSubscriptionStartDate(therapist.subscriptionStartDate ? therapist.subscriptionStartDate.slice(0,10) : '');
    setSubscriptionEndDate(therapist.subscriptionEndDate ? therapist.subscriptionEndDate.slice(0,10) : '');
    if (therapist.location && therapist.location.state) {
      fetchCities(therapist.location.state);
    }
    fetchAvailableSlots(therapist._id);
    setIsEditModalOpen(true);
  };

  const fetchCities = async (state) => {
    try {
      const response = await axios.get(`${config.API_URL}/api/cities/${state}`);
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
      toast.error('Gagal mendapatkan senarai bandar. Sila cuba lagi.');
    }
  };

  const fetchAvailableSlots = async (therapistId) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/therapists/${therapistId}/available-slots`
      );
      setAvailableSlots(response.data);
    } catch (error) {
      console.error('Error fetching available slots:', error);
      toast.error('Gagal mendapatkan slot tersedia. Sila cuba lagi.');
    }
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditTherapist(null);
    setErrors({});
    setCities([]);
    setAvailableSlots([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditTherapist((prev) => ({ ...prev, [name]: value }));
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setEditTherapist((prev) => ({
      ...prev,
      location: { ...prev.location, [name]: value },
    }));

    if (name === 'state') {
      fetchCities(value);
      setEditTherapist((prev) => ({
        ...prev,
        location: { ...prev.location, city: '' },
      }));
    }
  };

  const handleJenisRawatanChange = (gender) => {
    setEditTherapist((prev) => ({
      ...prev,
      jenisRawatan: {
        ...prev.jenisRawatan,
        [gender]: !prev.jenisRawatan[gender],
      },
    }));
  };

  const handleRawatanLainChange = (index, value) => {
    const updatedRawatanLain = [...editTherapist.jenisRawatan.rawatanLain];
    updatedRawatanLain[index] = value;
    setEditTherapist((prev) => ({
      ...prev,
      jenisRawatan: { ...prev.jenisRawatan, rawatanLain: updatedRawatanLain },
    }));
  };

  const addRawatanLain = () => {
    setEditTherapist((prev) => ({
      ...prev,
      jenisRawatan: {
        ...prev.jenisRawatan,
        rawatanLain: [...prev.jenisRawatan.rawatanLain, ''],
      },
    }));
  };

  const removeRawatanLain = (index) => {
    setEditTherapist((prev) => ({
      ...prev,
      jenisRawatan: {
        ...prev.jenisRawatan,
        rawatanLain: prev.jenisRawatan.rawatanLain.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

const validate = () => {
  let tempErrors = {};
  if (!editTherapist.name) tempErrors.name = 'Nama diperlukan.';
  if (!editTherapist.email) {
    tempErrors.email = 'Email diperlukan.';
  } else if (!/\S+@\S+\.\S+/.test(editTherapist.email)) {
    tempErrors.email = 'Email tidak sah.';
  }
  if (!editTherapist.phone) tempErrors.phone = 'Telefon diperlukan.';
  if (
    !editTherapist.location ||
    !editTherapist.location.state ||
    !editTherapist.location.city
  ) {
    tempErrors.location = 'Lokasi (negeri dan bandar) diperlukan.';
  }

  // Validasi Tarikh Langganan
  if (subscriptionStartDate && subscriptionEndDate) {
    const start = new Date(subscriptionStartDate);
    const end = new Date(subscriptionEndDate);
    if (end <= start) {
      tempErrors.subscriptionEndDate = 'Tarikh tamat harus selepas tarikh mula.';
    }
  } else {
    tempErrors.subscriptionDates = 'Tarikh mula dan tamat langganan diperlukan.';
  }

  setErrors(tempErrors);
  return Object.keys(tempErrors).length === 0;
};



  const handleSaveEdit = async () => {
    if (!validate()) return;

    try {
      // Kemas kini maklumat terapis
      await axios.put(
        `${config.API_URL}/therapists/${editTherapist._id}`,
        editTherapist
      );

      // Kemas kini langganan terapis
      await axios.put(
        `${config.API_URL}/admin/therapists/${editTherapist._id}/subscription`,
        { 
          subscriptionPlan,
          subscriptionStartDate,
          subscriptionEndDate 
        },
        {
          headers: {
            'x-auth-token': localStorage.getItem('authToken'),
          },
        }
      );

      // Kemas kini senarai terapis
      fetchTherapists();
      handleCloseEditModal();
      toast.success('Terapis berjaya dikemas kini.');
    } catch (error) {
      console.error('Error updating therapist:', error);
      toast.error('Ralat semasa mengemas kini terapis. Sila cuba lagi.');
    }
  };

  const handleDelete = async (therapistId) => {
    if (window.confirm('Adakah anda pasti ingin memadam terapis ini?')) {
      try {
        await axios.delete(`${config.API_URL}/therapists/${therapistId}`);
        setTherapists(
          therapists.filter((therapist) => therapist._id !== therapistId)
        );
        toast.success('Terapis berjaya dipadam.');
      } catch (error) {
        console.error('Error deleting therapist:', error);
        toast.error('Ralat semasa memadam terapis. Sila cuba lagi.');
      }
    }
  };

  return (
    <div className="manage-therapists-container">
      <h2>Urus Terapis</h2>
      <div className="therapists-list">
        {therapists.map((therapist) => (
          <div key={therapist._id} className="therapist-card">
            <img
              src={`${config.API_URL}${therapist.image || '/images/user.png'}`}
              alt={`${therapist.name || 'Unknown'} profile`}
              className="therapist-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/images/user.png';
              }}
            />
            <h3 className="therapist-name">{therapist.name || 'Unknown'}</h3>
            <div className="therapist-details">
              <p>
                <strong>Email:</strong> {therapist.email || 'Tidak disediakan'}
              </p>
              <p>
                <strong>Telefon:</strong>{' '}
                {therapist.phone || 'Tidak disediakan'}
              </p>
              <p>
                <strong>Lokasi:</strong> {therapist.location?.state || 'Unknown'}
                , {therapist.location?.city || 'Unknown'}
              </p>
              <p>
                <strong>Jenis Rawatan:</strong>
              </p>
              <ul className="treatment-list">
                <li>
                  Lelaki: {therapist.jenisRawatan?.lelaki ? 'Ya' : 'Tidak'}
                </li>
                <li>
                  Wanita: {therapist.jenisRawatan?.wanita ? 'Ya' : 'Tidak'}
                </li>
                {therapist.jenisRawatan?.rawatanLain &&
                  therapist.jenisRawatan.rawatanLain.map((rawatan, index) => (
                    <li key={index}>{rawatan}</li>
                  ))}
              </ul>
              <p>
                <strong>Tarikh Daftar:</strong>{' '}
                {therapist.createdAt
                  ? new Date(therapist.createdAt).toLocaleDateString()
                  : 'Unknown'}
              </p>
              <p>
                <strong>Pelan Langganan:</strong>{' '}
                {therapist.subscriptionPlan || 'Tidak ditetapkan'}
              </p>
            </div>
            <div className="manage-buttons">
              <button
                className="manage-button edit"
                onClick={() => handleEdit(therapist)}
              >
                Edit
              </button>
              <button
                className="manage-button delete"
                onClick={() => handleDelete(therapist._id)}
              >
                Padam
              </button>
            </div>
          </div>
        ))}
      </div>

      {isEditModalOpen && editTherapist && (
        <div className="modal-overlay" onClick={handleCloseEditModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header">
              <h2>Edit Terapis</h2>
              <span className="close-button" onClick={handleCloseEditModal}>
                &times;
              </span>
            </div>
            <div className="modal-body">
              <form>
                {/* Nama Terapis */}
                <div className="form-group">
                  <label>Nama Terapis</label>
                  <input
                    type="text"
                    name="name"
                    value={editTherapist.name || ''}
                    onChange={handleChange}
                    placeholder="Nama Terapis"
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>

                {/* Email Terapis */}
                <div className="form-group">
                  <label>Email Terapis</label>
                  <input
                    type="email"
                    name="email"
                    value={editTherapist.email}
                    onChange={handleChange}
                    placeholder="Email Terapis"
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>

                {/* Telefon Terapis */}
                <div className="form-group">
                  <label>Telefon Terapis</label>
                  <input
                    type="text"
                    name="phone"
                    value={editTherapist.phone}
                    onChange={handleChange}
                    placeholder="Telefon Terapis"
                  />
                  {errors.phone && (
                    <span className="error">{errors.phone}</span>
                  )}
                </div>

                {/* Lokasi Terapis */}
                <div className="form-group">
                  <label>Negeri</label>
                  <select
                    name="state"
                    value={editTherapist.location?.state || ''}
                    onChange={handleLocationChange}
                  >
                    <option value="">Pilih Negeri</option>
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <label>Bandar</label>
                  <select
                    name="city"
                    value={editTherapist.location?.city || ''}
                    onChange={handleLocationChange}
                  >
                    <option value="">Pilih Bandar</option>
                    {cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                  {errors.location && (
                    <span className="error">{errors.location}</span>
                  )}
                </div>

                {/* Jenis Rawatan */}
                <div className="form-group">
                  <label>Available for Gender:</label>
                  <div className="gender-toggle">
                    <label>
                      <span>Lelaki</span>
                      <button
                        type="button"
                        onClick={() => handleJenisRawatanChange('lelaki')}
                        className={
                          editTherapist.jenisRawatan?.lelaki ? 'active' : ''
                        }
                      >
                        {editTherapist.jenisRawatan?.lelaki ? (
                          <FaToggleOn />
                        ) : (
                          <FaToggleOff />
                        )}
                      </button>
                    </label>
                    <label>
                      <span>Wanita</span>
                      <button
                        type="button"
                        onClick={() => handleJenisRawatanChange('wanita')}
                        className={
                          editTherapist.jenisRawatan?.wanita ? 'active' : ''
                        }
                      >
                        {editTherapist.jenisRawatan?.wanita ? (
                          <FaToggleOn />
                        ) : (
                          <FaToggleOff />
                        )}
                      </button>
                    </label>
                  </div>
                </div>

                {/* Rawatan Lain */}
                <div className="form-group">
                  <label>Jenis Rawatan yang Dilakukan:</label>
                  <div className="rawatan-list">
                    {editTherapist.jenisRawatan?.rawatanLain?.map(
                      (rawatan, index) => (
                        <div key={index} className="rawatan-item">
                          <input
                            type="text"
                            value={rawatan}
                            onChange={(e) =>
                              handleRawatanLainChange(index, e.target.value)
                            }
                          />
                          <button
                            type="button"
                            onClick={() => removeRawatanLain(index)}
                          >
                            Hapus
                          </button>
                        </div>
                      )
                    )}
                    <button
                      type="button"
                      onClick={addRawatanLain}
                      className="add-rawatan"
                    >
                      Tambah Rawatan
                    </button>
                  </div>
                </div>

                {/* Tarikh Pendaftaran - Read Only */}
                <div className="form-group">
                  <label>Tarikh Daftar</label>
                  <input
                    type="text"
                    name="registrationDate"
                    value={new Date(
                      editTherapist.createdAt
                    ).toLocaleDateString()}
                    readOnly
                  />
                </div>

                {/* Pelan Langganan */}
                <div className="form-group">
                  <label>Pelan Langganan</label>
                  <select
                    value={subscriptionPlan}
                    onChange={(e) => setSubscriptionPlan(e.target.value)}
                  >
                    <option value="free_trial">Percubaan Percuma</option>
                    <option value="basic">Basic</option>
                    <option value="plus">Plus</option>
                  </select>
                </div>

                {/* Tarikh Tamat Langganan - Paparkan sahaja */}
                {/* Tarikh Mula Langganan */}
                <div className="form-group">
                  <label>Tarikh Mula Langganan</label>
                  <input
                    type="date"
                    value={subscriptionStartDate}
                    onChange={(e) => setSubscriptionStartDate(e.target.value)}
                  />
                </div>

                {/* Tarikh Tamat Langganan */}
                <div className="form-group">
                  <label>Tarikh Tamat Langganan</label>
                  <input
                    type="date"
                    value={subscriptionEndDate}
                    onChange={(e) => setSubscriptionEndDate(e.target.value)}
                  />
                      {errors.subscriptionEndDate && (
    <span className="error">{errors.subscriptionEndDate}</span>
  )}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="save-button" onClick={handleSaveEdit}>
                Simpan
              </button>
              <button className="cancel-button" onClick={handleCloseEditModal}>
                Batal
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageTherapists;
