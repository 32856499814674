// MyBookings.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './MyBookings.css';
import { format, parseISO, isSameDay } from 'date-fns';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCar } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

function MyBookings({ therapist }) {
  const [bookings, setBookings] = useState([]);
  const [todayBookingsCount, setTodayBookingsCount] = useState(0);

  useEffect(() => {
    if (therapist) {
      fetchBookings();
    }
  }, [therapist]);

  const fetchBookings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/therapist/bookings`, {
        headers: { 'x-auth-token': token },
      });
      console.log('Bookings fetched:', response.data);
      const sortedBookings = response.data.sort(
        (a, b) => new Date(a.bookingDate) - new Date(b.bookingDate)
      );
      setBookings(sortedBookings);
      setTodayBookingsCount(getTodayBookingsCount(sortedBookings));
    } catch (error) {
      console.error('Error fetching bookings:', error);
      toast.error('Gagal mendapatkan tempahan. Sila cuba lagi.');
    }
  };

  const handleMarkAsComplete = async (bookingId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${config.API_URL}/therapist/bookings/${bookingId}`,
        { status: 'Complete' },
        {
          headers: { 'x-auth-token': token },
        }
      );
      toast.success('Tempahan ditandakan sebagai selesai.');
      // Update the booking status in state
      setBookings((prevBookings) =>
        prevBookings.map((booking) =>
          booking._id === bookingId ? { ...booking, status: 'Complete' } : booking
        )
      );
    } catch (error) {
      console.error('Error updating booking status:', error);
      toast.error('Gagal mengemas kini status tempahan. Sila cuba lagi.');
    }
  };

  const getTodayBookingsCount = (bookings) => {
    const today = new Date();
    return bookings.filter((booking) =>
      isSameDay(parseISO(booking.bookingDate), today)
    ).length;
  };

  const handleDeleteBooking = async (bookingId) => {
    if (window.confirm('Adakah anda pasti mahu memadam tempahan ini?')) {
      try {
        await axios.delete(`${config.API_URL}/bookings/${bookingId}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        // Refresh bookings after deletion
        fetchBookings();
        toast.success('Tempahan berjaya dipadam.');
      } catch (error) {
        console.error('Error deleting booking:', error.response ? error.response.data : error);
        toast.error('Gagal memadam tempahan. Sila cuba lagi.');
      }
    }
  };

  return (
    <div className="my-bookings">
      <h2>Tempahan Saya</h2>
      <div>
        <h3>Tempahan Hari Ini: {todayBookingsCount}</h3>
      </div>
      {bookings.length > 0 ? (
        <table className="bookings-table">
          <thead>
            <tr>
              <th>Tarikh Tempahan</th>
              <th>Masa</th>
              <th>Rawatan</th>
              <th>Nama Klien</th>
              <th>Telefon Klien</th>
              <th>Jenis Perkhidmatan</th>
              <th>Lokasi</th>
              <th>Status</th>
              <th>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <tr key={booking._id}>
                <td>{format(parseISO(booking.bookingDate), 'dd/MM/yyyy')}</td>
                <td>
                  {booking.slotId
                    ? `${booking.slotId.startTime} - ${booking.slotId.endTime}`
                    : 'N/A'}
                </td>
                <td>{booking.treatment}</td>
                <td>{booking.userName}</td>
                <td>{booking.userPhone}</td>
                <td>
                  {booking.serviceType === 'incall' ? (
                    <span className="service-type incall">
                      <FontAwesomeIcon icon={faMapMarkerAlt} /> In-call
                    </span>
                  ) : (
                    <span className="service-type outcall">
                      <FontAwesomeIcon icon={faCar} /> Out-call
                    </span>
                  )}
                </td>
                <td>
                  {booking.serviceType === 'incall' ? (
                    <span className="location-info">Di Pusat Terapi</span>
                  ) : (
                    <span className="location-info outcall-address" title={booking.outcallAddress}>
                      {booking.outcallAddress}
                    </span>
                  )}
                </td>
                <td className={`status ${booking.status.toLowerCase()}`}>
                  {booking.status}
                </td>
                <td>
                  {booking.status === 'Progress' && (
                    <>
                      <button
                        className="complete-button"
                        onClick={() => handleMarkAsComplete(booking._id)}
                      >
                        Tandakan Selesai
                      </button>
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteBooking(booking._id)}
                      >
                        Padam
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Tiada tempahan buat masa ini.</p>
      )}
    </div>
  );
}

export default MyBookings;
