// AvailableSlot.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './AvailableSlot.css';
import EditSlotForm from './EditSlotForm';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO, isSameDay, startOfDay, addDays } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';

function AvailableSlot({ therapist }) {
  // Pastikan slotDuration adalah Number
  const [therapistState, setTherapistState] = useState(() => ({
    ...therapist,
    slotDuration: Number(therapist.slotDuration),
  }));

  const [treatments, setTreatments] = useState([]);
  const [slotsByTreatment, setSlotsByTreatment] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [highlightDates, setHighlightDates] = useState([]);
  const [polling, setPolling] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTreatments = useCallback(() => {
    const treatmentsData = therapist.jenisRawatan.rawatanLain || [];
    setTreatments(treatmentsData);
  }, [therapist]);

  const fetchSlots = useCallback(async () => {
    if (!polling) return;

    setIsLoading(true);
    try {
      const response = await axios.get(`${config.API_URL}/slots/${therapist._id}`);
      const slotsData = response.data;

      const today = startOfDay(new Date());

      const groupedByTreatment = {};

      slotsData.forEach((slot) => {
        const slotDate = parseISO(slot.date);
        if (slotDate >= today) {
          if (!groupedByTreatment[slot.treatment]) {
            groupedByTreatment[slot.treatment] = [];
          }
          groupedByTreatment[slot.treatment].push(slot);

          groupedByTreatment[slot.treatment].sort((a, b) => {
            const aDateTime = new Date(`${a.date}T${a.startTime}`);
            const bDateTime = new Date(`${b.date}T${b.startTime}`);
            return aDateTime - bDateTime;
          });
        }
      });

      if (Object.keys(groupedByTreatment).length === 0) {
        toast.info('Sila tambah slot available anda disini.');
      }

      setSlotsByTreatment(groupedByTreatment);
    } catch (error) {
      console.error('Error fetching slots:', error);
      setPolling(false);
      if (error.response && error.response.status !== 404) {
        toast.error('Gagal mendapatkan slot. Sila cuba lagi.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [therapist._id, polling]);

  useEffect(() => {
    if (therapist) {
      fetchTreatments();
      fetchSlots();
    }

    return () => {
      setPolling(false); // Stop polling when component unmounts
    };
  }, [therapist, fetchTreatments, fetchSlots]);

  useEffect(() => {
    // Update highlight dates when slots are fetched
    const dates = [];
    const today = startOfDay(new Date());
    Object.values(slotsByTreatment).forEach((slots) => {
      slots.forEach((slot) => {
        const date = parseISO(slot.date);
        if (date >= today && !slot.isBooked) {
          dates.push(date);
        }
      });
    });
    setHighlightDates(dates);
  }, [slotsByTreatment]);

  const handleEditSlot = (treatment) => {
    setSelectedTreatment(treatment);
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setSelectedTreatment(null);
  };

  const handleDeleteSlot = async (slotId) => {
    if (window.confirm('Adakah anda pasti mahu memadam slot ini?')) {
      try {
        await axios.delete(`${config.API_URL}/slots/${slotId}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        // Refresh slots after deletion
        fetchSlots();
        toast.success('Slot berjaya dipadam.');
      } catch (error) {
        console.error('Error deleting slot:', error);
        toast.error('Gagal memadam slot. Sila cuba lagi.');
      }
    }
  };

  const handleClearSlots = async (treatment) => {
    if (window.confirm(`Adakah anda pasti mahu memadam semua slot untuk ${treatment}?`)) {
      try {
        await axios.delete(`${config.API_URL}/slots/clear/${therapist._id}/${encodeURIComponent(treatment)}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        // Refresh slots after clearing
        fetchSlots();
        toast.success(`Semua slot untuk ${treatment} berjaya dipadam.`);
      } catch (error) {
        console.error('Error clearing slots:', error);
        toast.error('Gagal memadam semua slot. Sila cuba lagi.');
      }
    }
  };

  const handleDateSelect = (date) => {
    setFilterDate(date);
    setShowCalendar(false);
  };

  return (
    <div className="available-slot">
      <h2>Slot Tersedia</h2>

      {/* Calendar Filter */}
      <button onClick={() => setShowCalendar(true)} className="calendar-button">
        Pilih Tarikh
      </button>

      {showCalendar && (
        <div className="calendar-modal" onClick={() => setShowCalendar(false)}>
          <div className="calendar-container" onClick={(e) => e.stopPropagation()}>
            <ReactDatePicker
              selected={filterDate}
              onChange={handleDateSelect}
              inline
              highlightDates={highlightDates}
              dayClassName={(date) =>
                highlightDates.some((d) => isSameDay(d, date)) ? 'highlighted-date' : undefined
              }
              minDate={startOfDay(new Date())}
              filterDate={(date) => highlightDates.some((d) => isSameDay(d, date))}
            />
          </div>
        </div>
      )}

      <div className="treatment-card-container">
        {treatments.map((treatment, index) => (
          <div key={index} className="treatment-card">
            <h3>{treatment}</h3>
            <button onClick={() => handleEditSlot(treatment)} className="edit-slot-button">
              Edit Slot
            </button>
            <button onClick={() => handleClearSlots(treatment)} className="clear-slots-button">
              Padam Semua Slot
            </button>
            {/* Display slots for this treatment */}
            <div className="slot-list">
              {slotsByTreatment[treatment] && slotsByTreatment[treatment].length > 0 ? (
                // Group slots by date
                Object.entries(
                  slotsByTreatment[treatment].reduce((acc, slot) => {
                    if (filterDate && !isSameDay(parseISO(slot.date), filterDate)) {
                      return acc;
                    }
                    if (!acc[slot.date]) {
                      acc[slot.date] = [];
                    }
                    acc[slot.date].push(slot);
                    return acc;
                  }, {})
                ).map(([date, slots]) => (
                  <div key={date} className="date-group">
                    <h4>{date}</h4>
                    {slots.map((slot) => (
                      <div key={slot._id} className="slot-item">
                        {slot.startTime} - {slot.endTime} {slot.isBooked ? '(Ditempah)' : ''}
                        <button
                          className="delete-slot-button"
                          onClick={() => handleDeleteSlot(slot._id)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <p>Tiada slot tersedia untuk rawatan ini.</p>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Modal untuk mengedit slot */}
      {showEditModal && selectedTreatment && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <EditSlotForm
              therapist={therapistState}
              treatment={selectedTreatment}
              onClose={handleCloseModal}
              onSave={(updatedTherapist) => {
                console.log('Updated therapist di AvailableSlot.js sebelum set state:', updatedTherapist);

                // Gabungkan state lama dengan data baru
                setTherapistState((prevState) => ({
                  ...prevState,
                  ...updatedTherapist,
                  slotDuration: Number(updatedTherapist.slotDuration), // Pastikan slotDuration adalah Number
                }));

                // Panggil fetchSlots selepas state di-update
                setTimeout(() => {
                  fetchSlots();
                  toast.success('Slot berjaya ditambah.');
                }, 0);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AvailableSlot;
