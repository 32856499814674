// RegisterForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './RegisterForm.css';
import LocationSelector from './LocationSelector';
import config from '../config';



function RegisterForm({ onRegisterSuccess }) {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    location: { state: '', city: '' },
    jenisRawatan: {
      lelaki: false,
      wanita: false,
      rawatanLain: []
    }
  });
  const [errors, setErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [isEmailUnique, setIsEmailUnique] = useState(true);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [tempPassword, setTempPassword] = useState('');

  useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      location: formData.location
    }));
  }, [formData.location]);

  const showTempPasswordModal = (password) => {
    setTempPassword(password);
    setShowPasswordModal(true);
  };

  const copyPasswordAndRedirect = () => {
    navigator.clipboard.writeText(tempPassword)
      .then(() => {
        alert('Kata laluan sementara telah disalin!');
        setShowPasswordModal(false);
        onRegisterSuccess();
        navigate('/login', { 
          state: { 
            email: formData.email, 
            message: 'Sila log masuk dengan kata laluan sementara anda dan tukar segera.' 
          }
        });
      })
      .catch(err => {
        console.error('Gagal menyalin kata laluan:', err);
        alert('Gagal menyalin kata laluan. Sila salin secara manual.');
      });
  };

  const validateStep = async (stepNumber) => {
    const newErrors = {};
    switch (stepNumber) {
      case 1:
        if (!formData.name.trim()) newErrors.name = 'Nama diperlukan';
        if (!formData.email.trim()) newErrors.email = 'Email diperlukan';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Format email tidak sah';
        else {
          try {
            const response = await axios.get(`${config.API_URL}/check-email/${formData.email}`);
            setIsEmailUnique(response.data.isUnique);
            if (!response.data.isUnique) newErrors.email = 'Email sudah digunakan';
          } catch (error) {
            console.error('Error checking email:', error);
          }
        }
        break;
      case 2:
        if (!formData.phone.trim()) newErrors.phone = 'Nombor telefon diperlukan';
        if (!formData.location.state || !formData.location.city) newErrors.location = 'Lokasi diperlukan';
        break;
      case 3:
        if (!formData.jenisRawatan.lelaki && !formData.jenisRawatan.wanita) {
          newErrors.gender = 'Sila pilih sekurang-kurangnya satu jenis gender untuk rawatan';
        }
        if (formData.jenisRawatan.rawatanLain.length === 0) {
          newErrors.rawatanLain = 'Sila pilih sekurang-kurangnya satu jenis rawatan';
        }
        break;
      default:
        break;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (await validateStep(step)) {
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'email') {
      try {
        const response = await axios.get(`${config.API_URL}/check-email/${value}`);
        setIsEmailUnique(response.data.isUnique);
        if (!response.data.isUnique) {
          setErrors({ ...errors, email: 'Email sudah digunakan' });
        } else {
          setErrors({ ...errors, email: '' });
        }
      } catch (error) {
        console.error('Error checking email:', error);
      }
    }
  };

  const handleLocationChange = (newLocation) => {
    setFormData(prevData => ({
      ...prevData,
      location: newLocation
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    if (name === 'rawatanLain') {
      setFormData({
        ...formData,
        jenisRawatan: {
          ...formData.jenisRawatan,
          rawatanLain: checked
            ? [...formData.jenisRawatan.rawatanLain, value]
            : formData.jenisRawatan.rawatanLain.filter(item => item !== value)
        }
      });
    } else {
      setFormData({
        ...formData,
        jenisRawatan: {
          ...formData.jenisRawatan,
          [name]: checked,
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await validateStep(step)) {
      setShowPreview(true);
    }
  };

  const confirmSubmit = async () => {
    if (!isEmailUnique) {
      setErrors({ ...errors, email: 'Email sudah digunakan' });
      return;
    }

    try {
      const response = await axios.post(`${config.API_URL}/therapists`, formData);
      if (response.data && response.data.tempPassword) {
        showTempPasswordModal(response.data.tempPassword);
      } else {
        throw new Error('Temporary password not received');
      }
    } catch (error) {
      console.error('Error registering therapist:', error);
      alert('Gagal mendaftar terapis. Sila cuba lagi.');
    }
  };

  return (
    <div className="register-form-container">
      <h2>Register New Therapist</h2>
      <form onSubmit={handleSubmit} className="enhanced-form">
        {step === 1 && (
          <div className="form-step active">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="form-step active">
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              {errors.phone && <span className="error-message">{errors.phone}</span>}
            </div>
            <div className="form-group">
              <label>Location:</label>
              <LocationSelector
                selectedState={formData.location.state}
                selectedCity={formData.location.city}
                onChange={handleLocationChange}
              />
              {errors.location && <span className="error-message">{errors.location}</span>}
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="form-step active">
            <div className="form-group form-section">
              <label>Gender for Treatment:</label>
              <div className="checkbox-group horizontal">
                <div className="checkbox-item">
                  <span>Lelaki</span>
                  <input
                    type="checkbox"
                    name="lelaki"
                    checked={formData.jenisRawatan.lelaki}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="checkbox-item">
                  <span>Wanita</span>
                  <input
                    type="checkbox"
                    name="wanita"
                    checked={formData.jenisRawatan.wanita}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
              {errors.gender && <span className="error-message">{errors.gender}</span>}
            </div>
            <div className="form-group form-section">
              <label>Jenis Rawatan:</label>
              <div className="checkbox-group">
                <div className="checkbox-item">
                  <span>Bone Adjustment</span>
                  <input
                    type="checkbox"
                    name="rawatanLain"
                    value="Bone Adjustment"
                    onChange={handleCheckboxChange}
                    checked={formData.jenisRawatan.rawatanLain.includes('Bone Adjustment')}
                  />
                </div>
                <div className="checkbox-item">
                  <span>Urutan Full Body (tradisional/saraf)</span>
                  <input
                    type="checkbox"
                    name="rawatanLain"
                    value="Urutan Full Body (tradisional/saraf)"
                    onChange={handleCheckboxChange}
                    checked={formData.jenisRawatan.rawatanLain.includes('Urutan Full Body (tradisional/saraf)')}
                  />
                </div>
                <div className="checkbox-item">
                  <span>Bekam Darah</span>
                  <input
                    type="checkbox"
                    name="rawatanLain"
                    value="Bekam Darah"
                    onChange={handleCheckboxChange}
                    checked={formData.jenisRawatan.rawatanLain.includes('Bekam Darah')}
                  />
                </div>
              </div>
              {errors.rawatanLain && <span className="error-message">{errors.rawatanLain}</span>}
            </div>
          </div>
        )}

        {showPasswordModal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Kata Laluan Sementara</h2>
              <p>Kata laluan sementara anda ialah: <strong>{tempPassword}</strong></p>
              <p>Sila salin kata laluan ini dan tukar segera selepas log masuk.</p>
              <button onClick={copyPasswordAndRedirect}>Salin Kata Laluan & Teruskan</button>
            </div>
          </div>
        )}

        {!showPreview && (
          <div className="form-nav">
            {step > 1 && (
              <button type="button" onClick={handlePrev}>
                Sebelumnya
              </button>
            )}
            {step < 3 ? (
              <button type="button" onClick={handleNext}>
                Seterusnya
              </button>
            ) : (
              <button type="submit">Hantar</button>
            )}
          </div>
        )}

        {showPreview && (
          <div className="preview-section">
            <h3>Sila semak maklumat anda:</h3>
            <div className="preview-item">
              <strong>Nama:</strong> {formData.name}
            </div>
            <div className="preview-item">
              <strong>Email:</strong> {formData.email}
            </div>
            <div className="preview-item">
              <strong>Telefon:</strong> {formData.phone}
            </div>
            <div className="preview-item">
              <strong>Lokasi:</strong> {formData.location.state}, {formData.location.city}
            </div>
            <div className="preview-item">
              <strong>Gender untuk Rawatan:</strong>{' '}
              {[
                formData.jenisRawatan.lelaki && 'Lelaki',
                formData.jenisRawatan.wanita && 'Wanita'
              ].filter(Boolean).join(', ')}
            </div>
            <div className="preview-item">
              <strong>Jenis Rawatan:</strong>{' '}
              {formData.jenisRawatan.rawatanLain.join(', ')}
            </div>
            <div className="form-nav">
              <button type="button" onClick={() => setShowPreview(false)}>
                Kembali
              </button>
              <button type="button" onClick={confirmSubmit}>
                Sahkan & Hantar
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default RegisterForm;