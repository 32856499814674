import React, { useState, useEffect } from 'react';
import './EditProfileForm.css';
import InputField from './InputField';
import LocationSelector from './LocationSelector';

function EditProfileForm({ therapist, onUpdate, onCancel }) {
  const [formData, setFormData] = useState({
    name: therapist.name || '',
    email: therapist.email || '',
    phone: therapist.phone || '',
    location: {
      state: '',
      city: ''
    },
    jenisRawatan: {
      lelaki: therapist.jenisRawatan.lelaki || false,
      wanita: therapist.jenisRawatan.wanita || false,
      rawatanLain: therapist.jenisRawatan.rawatanLain || []
    },
    image: therapist.image || '/images/user.png',
    bookingCutoffTime: therapist.bookingCutoffTime || 30,
    inCall: therapist.inCall || false, // New field for in-call
    outCall: therapist.outCall || false, // New field for out-call
    alamatKedai: therapist.alamatKedai || '', // New field for shop address
    transportCharges: therapist.transportCharges || [],
});

  const [newTreatment, setNewTreatment] = useState({ name: '', price: '' });
  const [newTransportCharge, setNewTransportCharge] = useState({ km: '', price: '' });
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    // Parse location jika ia adalah string
    if (typeof therapist.location === 'string') {
      const [state, city] = therapist.location.split(',').map(item => item.trim());
      setFormData(prevData => ({
        ...prevData,
        location: { state, city }
      }));
    } else if (therapist.location && typeof therapist.location === 'object') {
      setFormData(prevData => ({
        ...prevData,
        location: therapist.location
      }));
    }
  }, [therapist]);

  const validate = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = 'Nama diperlukan.';
    if (!formData.email.trim()) newErrors.email = 'Email diperlukan.';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Format email tidak sah.';
    if (!formData.phone.trim()) newErrors.phone = 'Nombor telefon diperlukan.';
    if (!formData.location.state || !formData.location.city) newErrors.location = 'Sila pilih negeri dan bandar.';

    const { lelaki, wanita, rawatanLain } = formData.jenisRawatan;
    if (!lelaki && !wanita) newErrors.jenisRawatan = 'Sila pilih sekurang-kurangnya satu jenis gender untuk rawatan.';
    if (rawatanLain.length === 0) newErrors.rawatanLain = 'Sila pilih sekurang-kurangnya satu jenis rawatan.';

    if (formData.inCall && !formData.alamatKedai.trim()) {
      newErrors.alamatKedai = 'Alamat kedai diperlukan untuk in-call.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      jenisRawatan: { ...prevData.jenisRawatan, [name]: checked }
    }));
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: checked
    }));
  };

  const handleAddTreatment = () => {
    if (newTreatment.name && newTreatment.price) {
      const newTreatmentString = `${newTreatment.name} RM${newTreatment.price}`;
      setFormData(prevData => ({
        ...prevData,
        jenisRawatan: {
          ...prevData.jenisRawatan,
          rawatanLain: [...prevData.jenisRawatan.rawatanLain, newTreatmentString]
        }
      }));
      setNewTreatment({ name: '', price: '' });
    }
  };

  const handleAddTransportCharge = () => {
  if (newTransportCharge.km && newTransportCharge.price) {
    const charge = {
      km: parseInt(newTransportCharge.km),
      price: parseFloat(newTransportCharge.price)
    };
    
    setFormData(prevData => ({
      ...prevData,
      transportCharges: [...prevData.transportCharges, charge]
    }));
    setNewTransportCharge({ km: '', price: '' });
  }
};

  const handleDeleteTreatment = (treatment) => {
    setFormData(prevData => ({
      ...prevData,
      jenisRawatan: {
        ...prevData.jenisRawatan,
        rawatanLain: prevData.jenisRawatan.rawatanLain.filter(item => item !== treatment)
      }
    }));
  };

 const handleDeleteTransportCharge = (indexToDelete) => {
  setFormData(prevData => ({
    ...prevData,
    transportCharges: prevData.transportCharges.filter((_, index) => index !== indexToDelete)
  }));
};

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleLocationChange = (newLocation) => {
    setFormData(prevData => ({
      ...prevData,
      location: newLocation
    }));
  };

const handleSubmit = (e) => {
  e.preventDefault();
  if (validate()) {
    const formDataToSend = new FormData();
    
    // Handle basic fields
    for (const key in formData) {
      if (key === 'jenisRawatan' || key === 'location') {
        formDataToSend.append(key, JSON.stringify(formData[key]));
      } else if (key === 'transportCharges') {
        // Pastikan transportCharges dalam format yang betul
        formDataToSend.append('transportCharges', JSON.stringify(formData.transportCharges));
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    if (selectedFile) {
      formDataToSend.append('profileImage', selectedFile);
    }

    onUpdate(formDataToSend);
  }
};


  return (
    <form onSubmit={handleSubmit} className="edit-profile-form">
      <h2>Edit Profile</h2>

      <InputField
        label="Name:"
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        required
        error={errors.name}
      />

      <InputField
        label="Email:"
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        required
        error={errors.email}
      />

      <InputField
        label="Phone:"
        type="tel"
        id="phone"
        name="phone"
        value={formData.phone}
        onChange={handleInputChange}
        required
        error={errors.phone}
      />

      <LocationSelector
        selectedState={formData.location.state}
        selectedCity={formData.location.city}
        onChange={handleLocationChange}
      />
      {errors.location && <span className="error-message">{errors.location}</span>}

      <fieldset className="form-group">
        <legend>Jenis Rawatan yang Disediakan untuk:</legend>
        <div className="toggle-group">
          <label>
            Lelaki
            <div className="toggle-switch">
              <input
                type="checkbox"
                name="lelaki"
                checked={formData.jenisRawatan.lelaki}
                onChange={handleCheckboxChange}
              />
              <span className="toggle-slider round"></span>
            </div>
          </label>
          <label>
            Wanita
            <div className="toggle-switch">
              <input
                type="checkbox"
                name="wanita"
                checked={formData.jenisRawatan.wanita}
                onChange={handleCheckboxChange}
              />
              <span className="toggle-slider round"></span>
            </div>
          </label>
        </div>
        {errors.jenisRawatan && <span className="error-message">{errors.jenisRawatan}</span>}
      </fieldset>

      <fieldset className="form-group">
        <legend>Jenis Rawatan:</legend>
        <div className="button-group">
          {formData.jenisRawatan.rawatanLain.map((rawatan) => (
            <div key={rawatan} className="treatment-item">
              <button
                type="button"
                className="active"
                onClick={() => handleDeleteTreatment(rawatan)}
              >
                {rawatan} <span className="delete-icon">×</span>
              </button>
            </div>
          ))}
        </div>
        <div className="add-treatment">
          <input
            type="text"
            placeholder="Nama rawatan"
            value={newTreatment.name}
            onChange={(e) => setNewTreatment({ ...newTreatment, name: e.target.value })}
          />
          <input
            type="number"
            placeholder="Harga (RM)"
            value={newTreatment.price}
            onChange={(e) => setNewTreatment({ ...newTreatment, price: e.target.value })}
          />
          <button type="button" onClick={handleAddTreatment}>Tambah</button>
        </div>
        {errors.rawatanLain && <span className="error-message">{errors.rawatanLain}</span>}
      </fieldset>

      <fieldset className="form-group">
        <legend>Jenis Perkhidmatan:</legend>
        <div className="toggle-group">
          <label>
            In-Call
            <div className="toggle-switch">
              <input
                type="checkbox"
                name="inCall"
                checked={formData.inCall}
                onChange={handleToggleChange}
              />
              <span className="toggle-slider round"></span>
            </div>
          </label>
          {formData.inCall && (
            <div className="form-group">
              <label htmlFor="alamatKedai">Alamat Kedai:</label>
              <textarea
                id="alamatKedai"
                name="alamatKedai"
                value={formData.alamatKedai}
                onChange={handleInputChange}
                rows="4"
                className="large-textarea"
                required
              />
              {errors.alamatKedai && <span className="error-message">{errors.alamatKedai}</span>}
            </div>
          )}
          <label>
            Out-Call
            <div className="toggle-switch">
              <input
                type="checkbox"
                name="outCall"
                checked={formData.outCall}
                onChange={handleToggleChange}
              />
              <span className="toggle-slider round"></span>
            </div>
          </label>
          {formData.outCall && (
  <fieldset className="form-group">
    <legend>Transport Charges (per km):</legend>
    <div className="button-group">
      {formData.transportCharges.map((charge, index) => (
        <div key={index} className="treatment-item">
          <button
            type="button"
            className="active"
            onClick={() => handleDeleteTransportCharge(index)}
          >
            {`${charge.km} km - RM${charge.price}`}
            <span className="delete-icon">×</span>
          </button>
        </div>
      ))}
    </div>
    <div className="add-transport-charge">
      <input
        type="number"
        placeholder="Jumlah KM"
        value={newTransportCharge.km}
        onChange={(e) => setNewTransportCharge(prev => ({ ...prev, km: e.target.value }))}
        className="small-input"
      />
      <input
        type="number"
        placeholder="Harga (RM)"
        value={newTransportCharge.price}
        onChange={(e) => setNewTransportCharge(prev => ({ ...prev, price: e.target.value }))}
        className="small-input"
      />
      <button type="button" onClick={handleAddTransportCharge} className="add-button">
        Tambah
      </button>
    </div>
  </fieldset>
)}
         
        </div>
      </fieldset>

      <div className="form-group">
        <label htmlFor="profileImage">Profile Image:</label>
        <input
          type="file"
          id="profileImage"
          name="profileImage"
          onChange={handleFileChange}
          accept="image/*"
        />
      </div>

      <div className="form-group">
        <label>Booking Cutoff Time (minutes):</label>
        <input
          type="number"
          name="bookingCutoffTime"
          value={formData.bookingCutoffTime}
          onChange={handleInputChange}
          min={0}
        />
      </div>

      <div className="form-actions">
        <button type="submit" className="save-button">Save Changes</button>
        <button type="button" onClick={onCancel} className="cancel-button">Cancel</button>
      </div>
    </form>
  );
}

export default EditProfileForm;
