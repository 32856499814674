// TherapistList.js

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import './TherapistList.css';
import BookingForm from './BookingForm';
import UserLocationSelector from './UserLocationSelector';
import config from '../config';

const TherapistList = () => {
  const [therapists, setTherapists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableTherapists, setAvailableTherapists] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({ state: '', city: '' });
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [checkedCount, setCheckedCount] = useState(0); // For progress bar

  useEffect(() => {
    const fetchTherapists = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${config.API_URL}/therapists`);
        setTherapists(response.data);
        checkAvailability(response.data);
      } catch (error) {
        console.error('Error fetching therapists:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTherapists();
  }, []);

  const checkAvailability = async (therapistList) => {
    setLoadingSlots(true);
    const today = moment().tz('Asia/Kuala_Lumpur').startOf('day');
    setCheckedCount(0);

    const requests = therapistList.map(async (therapist) => {
      try {
        const response = await axios.get(`${config.API_URL}/slots/${therapist._id}`);
        const availableSlots = response.data.filter((slot) => {
          const slotDate = moment.tz(slot.date, 'Asia/Kuala_Lumpur').startOf('day');
          return !slot.isBooked && slotDate.isSameOrAfter(today) && slot.treatment;
        });

        setAvailableTherapists((prev) => ({
          ...prev,
          [therapist._id]: availableSlots.length > 0,
        }));
      } catch (error) {
        console.error(`Error checking availability for therapist ${therapist._id}:`, error);
        setAvailableTherapists((prev) => ({
          ...prev,
          [therapist._id]: false,
        }));
      } finally {
        setCheckedCount((prev) => prev + 1);
      }
    });

    await Promise.allSettled(requests);
    setLoadingSlots(false);
  };

  const handleBooking = (therapist) => {
    if (availableTherapists[therapist._id]) {
      setSelectedTherapist(therapist);
      setIsModalOpen(true);
    }
  };

  const handleCloseBookingForm = () => {
    setSelectedTherapist(null);
    setIsModalOpen(false);
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const filteredTherapists = therapists.filter((therapist) => {
    if (selectedLocation.state && therapist.location.state !== selectedLocation.state) {
      return false;
    }
    if (selectedLocation.city && therapist.location.city !== selectedLocation.city) {
      return false;
    }
    return true;
  });

  // Sort therapists based on availability
  const sortedTherapists = filteredTherapists.sort((a, b) => {
    const aAvailable = availableTherapists[a._id] ? 1 : 0;
    const bAvailable = availableTherapists[b._id] ? 1 : 0;
    return bAvailable - aAvailable;
  });

  const handleShareLink = useCallback((therapist) => {
    const therapistLink = `${window.location.origin}/therapist/${therapist._id}`;
    navigator.clipboard
      .writeText(therapistLink)
      .then(() => {
        alert('Link telah disalin ke clipboard. Anda boleh paste dan kongsikan.');
      })
      .catch((error) => {
        console.error('Gagal untuk copy link:', error);
      });
  }, []);

  return (
    <div className="therapist-list-container list-view">
      <div className="filter-container">
        <h3>Filter Lokasi</h3>
        <UserLocationSelector
          selectedState={selectedLocation.state}
          selectedCity={selectedLocation.city}
          onChange={handleLocationChange}
        />
      </div>
      <div className="progress-bar-container">
        {loadingSlots && (
          <div className="progress-bar">
            <p>
              Memeriksa slot ketersediaan... {checkedCount}/{filteredTherapists.length} therapist telah diperiksa
            </p>
            <div className="progress">
              <div
                className="progress-filled"
                style={{ width: `${(checkedCount / filteredTherapists.length) * 100}%` }}
              ></div>
            </div>
          </div>
        )}
      </div>
      <div className="card-container">
        {loading ? (
          <div className="loading-container">
            <div className="spinner"></div>
            <p>Sedang memuatkan senarai therapist, sila tunggu...</p>
          </div>
        ) : (
          sortedTherapists.map((therapist) => (
            <div
              key={therapist._id}
              className={`therapist-item ${
                availableTherapists[therapist._id] === false ? 'unavailable' : ''
              }`}
            >
              <img
                src={therapist.image || '/images/user.png'}
                alt={`${therapist.name} profile`}
                className="therapist-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/user.png';
                }}
              />
              <div className="therapist-info">
                <div className="therapist-name">{therapist.name}</div>
                <div className="therapist-details">
                  <span>
                    <strong>Location:</strong> {therapist.location?.state || 'Tidak dinyatakan'},{' '}
                    {therapist.location?.city || 'Tidak dinyatakan'}
                  </span>
                  <div className="treatment-list">
                    <strong>Jenis Rawatan:</strong>
                    <ul>
                      {therapist.jenisRawatan?.rawatanLain &&
                      therapist.jenisRawatan.rawatanLain.length > 0 ? (
                        therapist.jenisRawatan.rawatanLain.map((rawatan, index) => (
                          <li key={index}>{rawatan}</li>
                        ))
                      ) : (
                        <li>Tiada rawatan yang tersedia</li>
                      )}
                    </ul>
                  </div>
                  <span>
                    <strong>Available for:</strong>{' '}
                    {therapist.jenisRawatan?.lelaki && therapist.jenisRawatan?.wanita
                      ? 'Lelaki & Wanita'
                      : therapist.jenisRawatan?.lelaki
                      ? 'Lelaki'
                      : therapist.jenisRawatan?.wanita
                      ? 'Wanita'
                      : 'Tidak dinyatakan'}
                  </span>
                  <span>
                    <strong>Jenis Perkhidmatan:</strong>{' '}
                    {therapist.inCall && therapist.outCall
                      ? 'In-Call & Out-Call'
                      : therapist.inCall
                      ? 'In-Call'
                      : therapist.outCall
                      ? 'Out-Call'
                      : 'Tidak dinyatakan'}
                  </span>
                </div>
                {availableTherapists[therapist._id] === undefined ? (
                  // Show skeleton or loading placeholder
                  <div className="loading-placeholder">Memeriksa ketersediaan...</div>
                ) : availableTherapists[therapist._id] ? (
                  <div className="button-group">
                    <button className="booking-button" onClick={() => handleBooking(therapist)}>
                      Book Now
                    </button>
                    <button className="share-button" onClick={() => handleShareLink(therapist)}>
                      Share Link
                    </button>
                  </div>
                ) : (
                  <div className="no-slots-message">Tiada slot tersedia</div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      {isModalOpen && (
        <div className="booking-form-overlay">
          <div className="booking-form-modal" onClick={(e) => e.stopPropagation()}>
            <BookingForm selectedTherapist={selectedTherapist} onClose={handleCloseBookingForm} />
            <button onClick={handleCloseBookingForm} className="btn-close">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TherapistList;