// Broadcast.js

import React, { useState, useEffect } from 'react';
import {
  FaExclamationTriangle,
  FaPaperPlane,
  FaClock,
  FaTrashAlt,
  FaSpinner,
  FaCheckCircle,
  FaPauseCircle,
  FaHourglassHalf,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import './Broadcast.css';
import config from '../config';
import axios from 'axios';
import moment from 'moment-timezone';

const Broadcast = ({ therapistId }) => {
  const [campaignName, setCampaignName] = useState('');
  const [targetGroup, setTargetGroup] = useState('');
  const [message, setMessage] = useState('');
  const [minInterval, setMinInterval] = useState(60);
  const [estimatedCompletion, setEstimatedCompletion] = useState('');
  const [scheduleType, setScheduleType] = useState('instant');
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [schedule, setSchedule] = useState({
    startDate: '',
  });
  const [testNumber, setTestNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contactCounts, setContactCounts] = useState({
    prospect: 0,
    customer: 0,
    vvip: 0,
  });
  const [filterDate, setFilterDate] = useState('');
  const [seeding, setSeeding] = useState('');


  const timeSlots = Array.from({ length: 24 }, (_, i) => {
    const startHour = i.toString().padStart(2, '0') + ':00';
    const endHour = ((i + 1) % 24).toString().padStart(2, '0') + ':00';
    return `${startHour}-${endHour}`;
  });

  // Fungsi untuk toggle slot masa
  const toggleTimeSlot = (slot) => {
    setSelectedTimeSlots((prev) => {
      if (prev.includes(slot)) {
        return prev.filter((s) => s !== slot);
      } else {
        return [...prev, slot];
      }
    });
  };

  // Fungsi untuk mendapatkan kiraan kontak
  const fetchContactCounts = async (therapistId, filterDate) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/prospects/count/${therapistId}`, {
        headers: { 'x-auth-token': token },
        params: { filterDate },
      });
      setContactCounts(response.data);
    } catch (error) {
      console.error('Failed to fetch contact counts:', error);
    }
  };

  useEffect(() => {
    if (therapistId) {
      fetchContactCounts(therapistId, filterDate);
    }
  }, [therapistId, filterDate]);



  useEffect(() => {
    const calculateEstimatedCompletion = () => {
      const groupCount = contactCounts[targetGroup] || 0;
      const totalSeconds = groupCount * minInterval;

      // Kira jumlah slot masa yang dipilih dalam sehari (dalam saat)
      const totalSlotSeconds = selectedTimeSlots.length * 3600;

      if (totalSlotSeconds === 0) {
        setEstimatedCompletion('Sila pilih slot masa');
        return;
      }

      // Urutkan slot masa yang dipilih
      const sortedSlots = selectedTimeSlots.sort();

      // Dapatkan masa mula slot pertama
      const firstSlotStart = sortedSlots[0].split('-')[0];

      // Dapatkan zon waktu pengguna
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Dapatkan tarikh mula dengan masa mula slot pertama dalam zon waktu pengguna
      const startDateTime = moment.tz(`${schedule.startDate} ${firstSlotStart}`, 'YYYY-MM-DD HH:mm', timeZone);

      // Kira jumlah hari yang diperlukan
      const daysNeeded = Math.floor(totalSeconds / totalSlotSeconds) + 1;

      // Kira baki masa yang perlu dihantar pada hari terakhir
      const remainingSeconds = totalSeconds % totalSlotSeconds;

      // Kira masa anggaran siap
      let estimatedCompletionDate = startDateTime.clone().add(daysNeeded - 1, 'days');

      if (remainingSeconds === 0) {
        // Selesai pada akhir slot terakhir
        const lastSlot = sortedSlots[sortedSlots.length - 1];
        const lastSlotEnd = lastSlot.split('-')[1];
        estimatedCompletionDate = estimatedCompletionDate.clone().set({
          hour: parseInt(lastSlotEnd.split(':')[0]),
          minute: parseInt(lastSlotEnd.split(':')[1]),
          second: 0,
          millisecond: 0,
        });
      } else {
        // Kira masa selesai pada hari terakhir
        let secondsPassed = 0;
        for (const slot of sortedSlots) {
          const [start, end] = slot.split('-');
          const slotStartTime = estimatedCompletionDate.clone().set({
            hour: parseInt(start.split(':')[0]),
            minute: parseInt(start.split(':')[1]),
            second: 0,
            millisecond: 0,
          });
          const slotEndTime = estimatedCompletionDate.clone().set({
            hour: parseInt(end.split(':')[0]),
            minute: parseInt(end.split(':')[1]),
            second: 0,
            millisecond: 0,
          });

          let slotDuration = slotEndTime.diff(slotStartTime, 'seconds');
          if (slotDuration <= 0) {
            // Jika slot melintasi tengah malam
            slotEndTime.add(1, 'day');
            slotDuration = slotEndTime.diff(slotStartTime, 'seconds');
          }

          if (remainingSeconds - secondsPassed <= slotDuration) {
            estimatedCompletionDate = slotStartTime.clone().add(remainingSeconds - secondsPassed, 'seconds');
            break;
          } else {
            secondsPassed += slotDuration;
          }
        }
      }

      setEstimatedCompletion(estimatedCompletionDate.format('DD/MM/YYYY HH:mm'));
    };

    if (scheduleType === 'scheduled' && selectedTimeSlots.length > 0 && schedule.startDate) {
      calculateEstimatedCompletion();
    }
  }, [targetGroup, minInterval, contactCounts, scheduleType, schedule, selectedTimeSlots]);

 const handleTestSend = async () => {
    if (!testNumber || !message) {
      toast.error('Sila isi nombor telefon dan mesej untuk ujian');
      return;
    }

    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');
      const payload = {
        therapistId,
        testNumber,
        message,
      };

      const response = await axios.post('/api/broadcast/test-send', payload, {
        headers: {
          'x-auth-token': token,
        },
      });

      if (response.data.success) {
        toast.success('Mesej ujian berjaya dihantar');
      } else {
        throw new Error(response.data.error || 'Gagal menghantar mesej ujian');
      }
    } catch (error) {
      console.error('Test send error:', error);
      toast.error(error.message || 'Gagal menghantar mesej ujian');
    }

    setIsLoading(false);
  };

  // Fungsi untuk toggle pemilihan kempen
  const toggleCampaignSelection = (campaignId) => {
    setSelectedCampaigns((prevSelected) => {
      if (prevSelected.includes(campaignId)) {
        return prevSelected.filter((id) => id !== campaignId);
      } else {
        return [...prevSelected, campaignId];
      }
    });
  };

  // Fungsi untuk memadam kempen yang dipilih
  const handleDeleteCampaigns = async () => {
    if (selectedCampaigns.length === 0) {
      toast.error('Sila pilih kempen untuk dipadam');
      return;
    }

    if (!window.confirm('Adakah anda pasti mahu memadam kempen yang dipilih?')) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete('/api/broadcast/campaigns', {
        headers: {
          'x-auth-token': token,
        },
        data: { campaignIds: selectedCampaigns },
      });

      if (response.data.success) {
        toast.success('Kempen berjaya dipadam');
        // Kemas kini senarai kempen
        setCampaigns((prevCampaigns) =>
          prevCampaigns.filter((campaign) => !selectedCampaigns.includes(campaign._id))
        );
        setSelectedCampaigns([]);
      } else {
        throw new Error(response.data.error || 'Gagal memadam kempen');
      }
    } catch (error) {
      console.error('Delete campaign error:', error);
      toast.error(error.message || 'Gagal memadam kempen');
    }
  };

  const handleCreateCampaign = async (e) => {
    e.preventDefault();
    // Validasi
    if (scheduleType === 'scheduled' && selectedTimeSlots.length === 0) {
      toast.error('Sila pilih sekurang-kurangnya satu slot masa');
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      let startDateTime;
      let timeZone;

      if (scheduleType === 'scheduled') {
        // Dapatkan slot masa pertama yang dipilih
        const sortedSlots = [...selectedTimeSlots].sort();
        const firstSlotStart = sortedSlots[0].split('-')[0];

        // Dapatkan zon waktu pengguna
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Gabungkan tarikh mula dengan masa mula slot pertama
        const startDateTimeString = `${schedule.startDate} ${firstSlotStart}`;

        // Tukar kepada ISO string dalam zon waktu pengguna
        startDateTime = moment.tz(startDateTimeString, 'YYYY-MM-DD HH:mm', timeZone).format();
      }

      const payload = {
        therapistId,
        campaignName,
        targetGroup,
        message,
        minInterval,
        scheduleType,
        seeding,
        timeZone: scheduleType === 'scheduled' ? timeZone : undefined,
        schedule:
          scheduleType === 'scheduled'
            ? { ...schedule, startDate: startDateTime, timeSlots: selectedTimeSlots }
            : null,
        filterDate, // Tambahkan filterDate di sini
      };

      const response = await axios.post('/api/broadcast/campaign', payload, {
        headers: {
          'x-auth-token': token,
        },
      });

      if (response.data.success) {
        toast.success('Kempen broadcast berjaya dicipta dan dihantar');
        // Resetkan borang jika perlu
        setCampaignName('');
        setTargetGroup('');
        setMessage('');
        setSchedule({ startDate: '' });
        setSelectedTimeSlots([]);
        setFilterDate('');
        // Muat semula senarai kempen
        fetchCampaigns();
      } else {
        throw new Error(response.data.error || 'Gagal mencipta kempen broadcast');
      }
    } catch (error) {
      console.error('Campaign creation error:', error);
      toast.error(error.message || 'Gagal mencipta kempen broadcast');
    }
    setIsLoading(false);
  };

  const fetchCampaigns = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/broadcast/campaigns/${therapistId}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      setCampaigns(response.data.campaigns);
    } catch (error) {
      console.error('Failed to fetch campaigns:', error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, [therapistId]);

  return (
    <div className="broadcast-container">
      <div className="broadcast-warning">
        <FaExclamationTriangle className="warning-icon" />
        <p>
          PERHATIAN: Sila berhati-hati dalam menggunakan fungsi broadcast. Pastikan nombor telah cukup matang untuk
          broadcast dan ikut limit masa yang disarankan. Minimum interval yang disarankan adalah 60 saat.
        </p>
      </div>

      <div className="broadcast-form">
        <h3>Cipta Kempen Broadcast Baru</h3>

        <div className="form-group">
          <label>Nama Kempen:</label>
          <input
            type="text"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            placeholder="Masukkan nama kempen"
          />
        </div>

        <div className="form-group">
          <label>Pilih Kumpulan Sasaran:</label>
          <select value={targetGroup} onChange={(e) => setTargetGroup(e.target.value)}>
            <option value="">Pilih kumpulan</option>
            {contactCounts.prospect > 0 && (
              <option value="prospect">Prospek ({contactCounts.prospect})</option>
            )}
            {contactCounts.customer > 0 && (
              <option value="customer">Pelanggan ({contactCounts.customer})</option>
            )}
            {contactCounts.vvip > 0 && <option value="vvip">VVIP ({contactCounts.vvip})</option>}
          </select>
        </div>

        <div className="form-group">
  <label>Jenis Seeding:</label>
  <select value={seeding} onChange={(e) => setSeeding(e.target.value)}>
    <option value="">Pilih jenis seeding</option>
    {[...Array(20)].map((_, i) => (
      <option key={i} value={`seeding ${i + 1}`}>
        Seeding {i + 1}
      </option>
    ))}
  </select>
</div>


        {/* Medan Penapis Tarikh */}
        <div className="form-group">
          <label>Pilih Tarikh Prospek:</label>
          <input
            type="date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Mesej:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Masukkan mesej broadcast"
            rows={4}
          />
        </div>

        <div className="test-section">
        <h4>Ujian Broadcast</h4>
        <div className="form-group">
          <input
            type="text"
            value={testNumber}
            onChange={(e) => setTestNumber(e.target.value)}
            placeholder="Masukkan nombor telefon untuk ujian"
          />
          <button
            onClick={handleTestSend}
            disabled={isLoading}
            className="test-button"
          >
            <FaPaperPlane /> Hantar Ujian
          </button>
        </div>
      </div>

      <div className="form-group">
        <label>
          <FaClock /> Minimum Interval (saat):
        </label>
        <input
          type="number"
          min={60}
          value={minInterval}
          onChange={(e) => setMinInterval(Number(e.target.value))}
        />
      </div>

      <div className="form-group">
        <label>Jenis Jadual:</label>
        <select value={scheduleType} onChange={(e) => setScheduleType(e.target.value)}>
          <option value="instant">Broadcast Segera</option>
          <option value="scheduled">Broadcast Berjadual</option>
        </select>
      </div>

      {scheduleType === 'scheduled' && (
        <>
          <div className="form-group">
            <label>Pilih Slot Masa:</label>
            <div className="time-slots">
              {timeSlots.map((slot) => (
                <div
                  key={slot}
                  className={`time-slot ${selectedTimeSlots.includes(slot) ? 'selected' : ''}`}

                  onClick={() => toggleTimeSlot(slot)}
                >
                  {slot}
                </div>
              ))}
            </div>
          </div>

          <div className="form-group">
            <label>Tarikh Bermula:</label>
            <input
              type="date"
              value={schedule.startDate}
              onChange={(e) => setSchedule({ ...schedule, startDate: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label>Anggaran Masa Siap:</label>
            <p>{estimatedCompletion}</p>
          </div>
        </>
      )}

        <button
          onClick={handleCreateCampaign}
          disabled={isLoading}
          className="create-campaign-button"
        >
          Cipta Kempen Broadcast      </button>
    </div>

    {/* Paparkan senarai kempen */}
    <div className="campaign-list">
      <h3>Senarai Kempen Broadcast</h3>
      <button
        onClick={handleDeleteCampaigns}
        disabled={selectedCampaigns.length === 0}
        className="delete-campaign-button"
      >
        <FaTrashAlt /> Padam Kempen Terpilih
      </button>
      <div className="table-responsive">
        <table className="campaign-table">
<thead>
  <tr>
    <th>
      <input
        type="checkbox"
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedCampaigns(campaigns.map((c) => c._id));
          } else {
            setSelectedCampaigns([]);
          }
        }}
        checked={selectedCampaigns.length === campaigns.length && campaigns.length > 0}
      />
    </th>
    <th>Nama Kempen</th>
    <th>Kumpulan Sasaran</th>
    <th>Tarikh Mula</th>
    <th>Slot Masa</th>
    <th>Jenis Broadcast</th>
    <th>Tarikh Prospect Dipilih</th>
    <th>Jenis Seeding</th>
    <th>Status</th>
  </tr>
</thead>

<tbody>
  {campaigns.map((campaign) => (
    <tr key={campaign._id}>
      <td>
        <input
          type="checkbox"
          checked={selectedCampaigns.includes(campaign._id)}
          onChange={() => toggleCampaignSelection(campaign._id)}
        />
      </td>
      <td>{campaign.name}</td>
      <td>{campaign.targetGroup}</td>
      <td>{campaign.schedule?.startDate ? moment(campaign.schedule.startDate).format('DD/MM/YYYY') : '-'}</td>
      <td>{campaign.schedule?.timeSlots?.join(', ') || '-'}</td>
      <td>{campaign.scheduleType || '-'}</td>
      <td>{campaign.filterDate ? moment(campaign.filterDate).format('DD/MM/YYYY') : '-'}</td>
      <td>{campaign.seeding || '-'}</td>
      <td className={`status ${campaign.status}`}>
        {campaign.status === 'pending' && <FaHourglassHalf />}
        {campaign.status === 'active' && <FaSpinner className="spin-icon" />}
        {campaign.status === 'completed' && <FaCheckCircle />}
        {campaign.status === 'paused' && <FaPauseCircle />}
        &nbsp;{campaign.status}
      </td>
    </tr>
  ))}
</tbody>
        </table>
      </div>
    </div>
  </div>
);
};
export default Broadcast;
